import React from "react";
const TextoAlertaInfo = ({ texto }) => {
  return (
    <div className="col-12 text-center mt-4">
      {" "}
      <div className="alert alert-info">{texto}</div>
    </div>
  );
};

export default TextoAlertaInfo;
